import { AuthApi } from './endpoint';

export function generateCode(payload) {
  return AuthApi.post('/auth/generate-code', payload);
}

export function getRulesLogin() {
  return AuthApi.get('/auth/user-rules', null, {
    'x-api-key': process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
  });
}
